* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
    min-height: 100vh;
}
.header {
    width: 100%;
    height: 70px;
    background: rgb(243, 243, 243);

    padding: 25px;
    display: flex;
    align-items: center;
}

.container {
    padding-left: 25px;
    padding-right: 25px;

    display: flex;
}

.station-list {
    background: rgb(245, 245, 245);
    width: 300px;
}
.station-list .station {
    padding: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.station-list .station:hover {
    background: rgb(232, 232, 232);
}
.station-list .station .station-name {
    font-size: 25px;
}
.station-list .station .play-button {
    padding: 5px;
    margin-left: 5px;
}

.audio-player-holder {
    padding: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.audio-player-holder .player {
    background: rgb(234, 234, 234);
    border-radius: 5px;
    width: 500px;
    padding: 15px;
}
.audio-player-holder .player .player-image {
    width: 50%;
    display: block;
    margin: 0 auto;
}

.audio-player-holder .player .player-station {
    text-align: center;
    font-size: 40px;
}

.audio-player-holder .player .audio-player {
    display: block;
    margin: 0 auto;
    margin-top: 25px;
    width: 90%;
}
.audio-player-holder .player .meta-data {
    text-align: center;
    margin-top: 15px;
}
